import React, { useState } from "react";
import editIcon from "../../../assets/images/edit-icon.png";

function EditCell(props) {
  const [isUserTyping, setIsUserTyping] = useState(false);
  const [isClick, setIsClick] = useState(false);
  return (
    <>
      {props.link ? (
        <>
          {isClick ? (
            <input
              type={props.type}
              value={isUserTyping ? props.link : props.value}
              onChange={(e) => {
                setIsUserTyping(true);
                props.setValue(e.target.value);
              }}
              onFocus={() => setIsUserTyping(true)}
              onBlur={() => {
                setIsUserTyping(false);
                setIsClick(false);
              }}
              autoFocus
              onKeyDown={props.handleSubmit}
              className={`${props.style} bg-transparent p-0 m-0 focus:outline-2 focus:outline-blue-500 focus:rounded shadow-none outline-none`}
              style={{ boxShadow: "none", border: "none" }}
            />
          ) : (
            <div className="flex">
              <a className="underline" target="_blank" href={props.link}>
                {props.value}
              </a>

              <div
                onDoubleClick={(e) => {
                  setIsClick(true);
                  setIsUserTyping(true);
                }}
                className="cursor-pointer opacity-0 w-2 flex items-center"
              >
                {" "}
                <img src={editIcon} alt="icon" className="mb-1 ms-2" />
              </div>
            </div>
          )}
        </>
      ) : (
        <input
          type={props.type}
          value={props.value}
          min={props.min}
          onKeyDown={props.handleSubmit}
          onChange={(e) => props.setValue(e.target.value)}
          className={`${props.style} bg-transparent p-0 m-0 focus:outline-2 focus:outline-blue-500 focus:rounded`}
          style={{ boxShadow: "none", border: "none" }}
        />
      )}
    </>
  );
}

export default EditCell;
