import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { confirmPassword } from "../../store/confirmSlice";
import bg from "../../assets/images/login.png";
import Swal from "sweetalert2";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform validation here
    const validationErrors = {};
    if (!password) {
      validationErrors.password = "Password is required";
    }
    if (password && password.length < 8) {
      validationErrors.password = "Password must be more than 8 characters.";
    }
    if (!confirmPass) {
      validationErrors.confirmPass = "Confirm Password is required";
    }
    if (confirmPass && confirmPass.length < 8) {
      validationErrors.confirmPass = "Password must be more than 8 characters.";
    }

    if (
      password &&
      confirmPass &&
      password !== confirmPass &&
      password.length >= 8 &&
      confirmPass.length >= 8
    ) {
      validationErrors.confirmPass = "Passwords not matching";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Submit the form

      dispatch(
        confirmPassword({
          data: {
            id: id,
            password: password,
          },
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.status) {
            Swal.fire({
              icon: "success",
              title: "Password updated successfully",
            });
            navigate("/");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
  };

  return (
    <div>
      <div className="split left">
        <img src={bg} alt="icon" className="login-img" />
      </div>
      <div className="split right flex flex-col justify-center items-center">
        <form onSubmit={handleSubmit}>
          <div className="login-heading mb-4 mt-1">Forget Password</div>

          <div className="flex flex-col mb-3">
            <label htmlFor="password" className="login-text mb-1">
              Password
            </label>
            <input
              type="password"
              id="password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => {
                if (e.target.value) {
                  let error = errors;
                  delete error.password;
                  setErrors(error);
                }
                setPassword(e.target.value);
              }}
              className={`login-input border ${
                errors.password ? "border-red-500" : "border-gray-300"
              } p-2`}
            />
            {errors.password && (
              <p className="login-text2 text-red-500">{errors.password}</p>
            )}
          </div>
          <div className="flex flex-col">
            <label htmlFor="confirmPass" className="login-text mb-1">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPass"
              placeholder="Enter Password"
              value={confirmPass}
              onChange={(e) => {
                if (e.target.value) {
                  let error = errors;
                  delete error.confirmPass;
                  setErrors(error);
                }
                setConfirmPass(e.target.value);
              }}
              className={`login-input border ${
                errors.confirmPass ? "border-red-500" : "border-gray-300"
              } p-2`}
            />
            {errors.confirmPass && (
              <p className="login-text2 text-red-500">{errors.confirmPass}</p>
            )}
          </div>
          <div className="flex justify-center">
            <button type="submit" className="login-btn px-5 py-2 mt-3">
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
