import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeStatus } from "../../../store/changeStatusSlice";
import { changeEditor } from "../../../store/changeEditorSlice";
import { useDrag, useDrop } from "react-dnd";
import { dragDrop } from "../../../store/dragDropSlice";
import downArrow from "../../../assets/images/down-arrow-logo.svg";

import Icon01 from "../../../assets/images/table-icon-01.svg";
import Icon02 from "../../../assets/images/table-icon-02.svg";
import Icon03 from "../../../assets/images/table-icon-03.svg";
import Icon04 from "../../../assets/images/table-icon-04.svg";
import Icon05 from "../../../assets/images/table-icon-05.svg";
import Icon06 from "../../../assets/images/table-icon-06.svg";
import Icon07 from "../../../assets/images/table-icon-07.svg";
import Icon08 from "../../../assets/images/table-icon-08.svg";
import Icon09 from "../../../assets/images/table-icon-09.svg";
import Icon10 from "../../../assets/images/table-icon-10.svg";
import Comment from "../Comment/comment";
import TableRow from "./TableRow";
import { getEditor } from "../../../store/getEditorSlice";
import Swal from "sweetalert2";

const statusList = [
  "Backlog",
  "In Progress",
  "Review",
  "Completed",
  "Declined",
];
const statusList2 = [
  "In Queue",
  "Initial Review",
  "Editing",
  "Final Review",
  "Revise",
  "Approved",
];

function ProjectTable(props) {
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isTableVisible, setIsTableVisible] = useState(true);

  const toggleTableVisibility = () => {
    setIsTableVisible(!isTableVisible);
  };

  const [selectedColor, setSelectedColor] = useState("#6E8FFF");

  const { data: editorList, status: editorStatus } = useSelector(
    (state) => state.getEditor
  );

  useEffect(() => {
    if (role === "admin") dispatch(getEditor({ token: token }));
  }, []);

  const handleSelectEditor = (event, id) => {
    props.setChangeStatusCheck(false);

    const selectedOption = event;
    dispatch(
      changeEditor({
        token: token,
        data: { projectId: id, editorId: selectedOption.id },
      })
    )
      .unwrap()
      .then(() => {
        props.setChangeStatusCheck(true);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  function generateTable() {
    let _currentPageData;

    if ((props?.filter || props?.type) && props?.client) {
      if (props.page === "project-management") {
        _currentPageData = props.data?.filter(
          (item) =>
            (item.currentStage === props.filter ||
              item.projectType === props.type) &&
            item.clientId === parseInt(props.client)
        );
      } else {
        _currentPageData = props.data?.filter(
          (item) =>
            (item.currentStage === props.filter ||
              item.projectType === props.type) &&
            item.clientId === parseInt(props.client) &&
            item.completed === false
        );
      }

      setCurrentPageData(_currentPageData);
    } else if (props?.filter || props?.type) {
      if (props.page === "project-management") {
        _currentPageData = props.data?.filter(
          (item) =>
            item.currentStage === props.filter ||
            item.projectType === props.type
        );
      } else {
        _currentPageData = props.data?.filter(
          (item) =>
            (item.currentStage === props.filter ||
              item.projectType === props.type) &&
            item.completed === false
        );
      }

      setCurrentPageData(_currentPageData);
    }

    props.setIsLoading(false);
  }
  function goToPage(page) {
    setCurrentPage(page);
  }
  const renderPaginationButtons = () => {
    const buttons = [];

    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <button
          key={i}
          className={`inline-flex items-center px-4 py-2 border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150 ${
            i === currentPage ? "bg-gray-800" : ""
          }`}
          onClick={() => goToPage(i)}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };
  const comment = () => {
    return (
      <>
        <button
          data-modal-target="authentication-modal"
          data-modal-toggle="authentication-modal"
          className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button"
        >
          comment
        </button>

        <div
          id="authentication-modal"
          tabindex="-1"
          aria-hidden="true"
          className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div className="relative w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="px-4 py-6 lg:px-8 m-5">
                <form className="flex flex-col space-y-6" action="#">
                  <div>
                    <label
                      for="comment"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Edit Comment
                    </label>
                    <input type="text" id="comment" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleSelectStatus = (event, id, type) => {
    props.setChangeStatusCheck(false);

    const selectedOption = event;
    if (type === "Video Editing") {
      dispatch(
        changeStatus({
          token: token,
          data: { id: id, name: selectedOption, projectType: type },
        })
      )
        .unwrap()
        .then(() => {
          props.setChangeStatusCheck(true);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    } else {
      dispatch(
        changeStatus({
          token: token,
          data: { id: id, name: selectedOption },
        })
      )
        .unwrap()
        .then(() => {
          props.setChangeStatusCheck(true);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
  };

  const handleDropItem = (itemId, targetTableId, targetTableName) => {
    // Move the item from the source table to the target table
    //console.log(itemId, targetTableId, targetTableName);
    // let sourceItems, setSourceItems, targetItems, setTargetItems;
    let data = props.data;
    let type;
    const updatedItems = data.map((item) => {
      if (item.id === itemId) {
        type = item.projectType;
        return { ...item, currentStage: targetTableName };
      }

      return item;
    });

    props.setProjectListData(updatedItems);
    //console.log(type);
    if (type === "Other Projects") {
      dispatch(
        changeStatus({
          token: token,
          data: { id: itemId, name: targetTableName },
        })
      );
    } else {
      dispatch(
        dragDrop({
          token: token,
          data: {
            id: itemId,
            name: targetTableName,
            projectType: "Video Editing",
          },
        })
      );
    }
  };

  const [, drop] = useDrop({
    accept: "row",
    drop: (droppedItem) =>
      handleDropItem(droppedItem.id, props.tableID, props.tableName),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    generateTable();
  }, [currentPage, props.data]);

  return (
    <>
      <table
        className="w-full mb-2 whitespace-nowrap"
        ref={drop}
        title={props?.tableName}
        tableId={props?.tableID}
        style={{
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
        }}
      >
        {props?.filter === "Brief" ||
        props?.filter === "Backlog" ||
        props?.type === "Video Editing" ? (
          <thead className="editor-project-table-text">
            <tr className="">
              <th style={{ width: "10px" }}></th>
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon01} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">Title</div>
                </div>
              </th>
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon02} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">Start Date</div>
                </div>
              </th>
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon03} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">Editor</div>
                </div>
              </th>
              {props.page === "dashboard" && (
                <th className="px-4 py-1">
                  <div className="flex">
                    <img src={Icon04} alt="icon" className="w-4 pe-1" />
                    <div className="editor-project-table-text">Status</div>
                  </div>
                </th>
              )}
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon05} alt="icon" className="w-4 pe-1" />
                  {props.page === "dashboard" ? (
                    <div className="editor-project-table-text">Due Date</div>
                  ) : (
                    <div className="editor-project-table-text">Deadline</div>
                  )}
                </div>
              </th>
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon06} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">
                    Internal Comments
                  </div>
                </div>
              </th>
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon07} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">Brief Link</div>
                </div>
              </th>
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon08} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">
                    Talent Footage
                  </div>
                </div>
              </th>
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon09} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">Frameio</div>
                </div>
              </th>
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon10} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">Final Folder</div>
                </div>
              </th>
            </tr>
          </thead>
        ) : (
          <thead className="editor-project-table-text opacity-0">
            <tr className="">
              <th style={{ width: "10px" }}></th>
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon01} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">Title</div>
                </div>
              </th>
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon02} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">Start Date</div>
                </div>
              </th>
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon03} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">Editor</div>
                </div>
              </th>
              {props.page === "dashboard" && (
                <th className="px-4 py-1">
                  <div className="flex">
                    <img src={Icon04} alt="icon" className="w-4 pe-1" />
                    <div className="editor-project-table-text">Status</div>
                  </div>
                </th>
              )}
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon05} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">Due Date</div>
                </div>
              </th>
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon06} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">
                    Internal Comments
                  </div>
                </div>
              </th>
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon07} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">Brief Link</div>
                </div>
              </th>
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon08} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">
                    Talent Footage
                  </div>
                </div>
              </th>
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon09} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">Frameio</div>
                </div>
              </th>
              <th className="px-4 py-1">
                <div className="flex">
                  <img src={Icon10} alt="icon" className="w-4 pe-1" />
                  <div className="editor-project-table-text">Final Folder</div>
                </div>
              </th>
            </tr>
          </thead>
        )}
        {(props.filter || props.type) && (
          <tr>
            <td style={{ width: "10px" }}></td>
            <td className="px-4 py-1">
              <div
                className={`editor-project-table-scrolldown flex pb-1 pt-1 cursor-pointer`}
                onClick={toggleTableVisibility}
              >
                {props.filter || props.type}

                {isTableVisible ? (
                  <img src={downArrow} alt="" className="ms-1" />
                ) : (
                  <img src={downArrow} alt="" className="ms-1 rotate-180" />
                )}
              </div>
            </td>
          </tr>
        )}
        <tbody
          className={`editor-project-table-text transition-opacity transition-transform ${
            isTableVisible ? "scroll-enter-active" : "scroll-exit-active"
          }`}
        >
          {!props.isLoading &&
            currentPageData?.length > 0 &&
            isTableVisible && (
              <>
                {currentPageData
                  // ?.filter(
                  //   (item) =>
                  //     (item.currentStage === props.filter &&
                  //       item.projectType === props.project) ||
                  //     item.projectType === props.type
                  // )
                  ?.map((item, index) => (
                    <TableRow
                      index={index}
                      project={props.project}
                      key={item.id}
                      id={item.id}
                      handleSelectStatus={handleSelectStatus}
                      handleSelectEditor={handleSelectEditor}
                      item={item}
                      page={props?.page}
                      editorList={editorList?.data}
                      setAddProject={props.setAddProject}
                    />
                  ))}
              </>
            )}
        </tbody>
      </table>
      <div>
        {!props.isLoading && currentPageData?.length <= 0 && (
          <div className={`flex justify-center sidebar-heading-2 p-2`}>
            No Data Found
          </div>
        )}
        {props.isLoading && (
          <div className="flex justify-center p-5">
            <svg
              aria-hidden="true"
              role="status"
              className="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="#1C64F2"
              />
            </svg>
          </div>
        )}
      </div>
    </>
  );
}

export default ProjectTable;
