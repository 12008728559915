import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProject } from "../../../store/getProjectSlice";
import { getStatsEditor } from "../../../store/getStatsEditorSlice";
import ProjectTable from "../ProjectTable/projectTable";
import Header from "./header";
import { getFirebaseToken } from "../../Firebase/firebase";
import { Notification } from "../../../store/notificationSlice";

function Dashboard() {
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const [client, setClient] = useState();
  const [search, setSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState("All Projects");
  const [sort, setSort] = useState();
  const [changeStatusCheck, setChangeStatusCheck] = useState(true);
  const [addProject, setAddProject] = useState(false);

  const { data: projectList, status: projectStatus } = useSelector(
    (state) => state.getProject
  );
  const { data: statsEditor, status: statsEditorStatus } = useSelector(
    (state) => state.getStatsEditor
  );

  const handleGetFirebaseToken = () => {
    getFirebaseToken()
      .then((firebaseToken) => {
        //console.log("Firebase token: ", firebaseToken);
        if (firebaseToken) {
          dispatch(
            Notification({ token: token, data: { fcm_token: firebaseToken } })
          );
        }
      })
      .catch((err) =>
        console.error("An error occured while retrieving firebase token. ", err)
      );
  };

  useEffect(() => {
    handleGetFirebaseToken();
  }, []);

  useEffect(() => {
    if (changeStatusCheck) dispatch(getStatsEditor({ token: token }));
  }, [changeStatusCheck]);

  useEffect(() => {
    if (addProject) dispatch(getStatsEditor({ token: token }));
  }, [addProject]);

  useEffect(() => {
    setIsLoading(true);

    if (project === "All Projects" && changeStatusCheck)
      dispatch(getProject({ token: token, name: search, sortBy: sort }));
    else if (changeStatusCheck)
      dispatch(
        getProject({ token: token, status: true, name: search, sortBy: sort })
      );
  }, [client, search, project, changeStatusCheck, sort]);

  useEffect(() => {
    if (addProject) {
      setIsLoading(true);
      if (project === "All Projects" && changeStatusCheck)
        dispatch(getProject({ token: token, name: search, sortBy: sort }));
      else if (changeStatusCheck)
        dispatch(
          getProject({ token: token, status: true, name: search, sortBy: sort })
        );
    }
  }, [addProject]);

  return (
    <div className="sm:ml-16 flex flex-col lg:h-screen">
      <div>
        <div className="flex sm:p-5 lg:flex-row flex-col">
          <div className="dashboard-header-card-text3 flex justify-center items-center lg:me-20">
            Overview
          </div>
          <div className="flex flex-row justify-center">
            <div className="sm:flex justify-around">
              <div className="dashboard-header-card ">
                {statsEditor?.data && (
                  <div className="dashboard-header-card-text1">
                    {statsEditor?.data[0]?.count}
                  </div>
                )}
                <div className="dashboard-header-card-text2">
                  Incoming Projects
                </div>
              </div>
              <div className="dashboard-header-card ">
                {statsEditor?.data && (
                  <div className="dashboard-header-card-text1">
                    {statsEditor?.data[1]?.count}
                  </div>
                )}
                <div className="dashboard-header-card-text2">
                  Ongoing Projects
                </div>
              </div>
            </div>
            <div className="sm:flex justify-around">
              <div className="dashboard-header-card ">
                {statsEditor?.data && (
                  <div className="dashboard-header-card-text1">
                    {statsEditor?.data[2]?.count}
                  </div>
                )}
                <div className="dashboard-header-card-text2">
                  Initial Review
                </div>
              </div>
              <div className="dashboard-header-card ">
                {statsEditor?.data && (
                  <div className="dashboard-header-card-text1">
                    {statsEditor?.data[3]?.count}
                  </div>
                )}
                <div className="dashboard-header-card-text2">Final Review</div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Header
            setClient={setClient}
            setSearch={setSearch}
            setProject={setProject}
            project={project}
            setSort={setSort}
            setAddProject={setAddProject}
          />
        </div>
      </div>

      <div className="mt-5 overflow-x-auto pb-40">
        <div style={{ zIndex: "9", position: "relative" }}>
          <ProjectTable
            type={"Video Editing"}
            client={client}
            page={"dashboard"}
            color={"#FFF"}
            data={projectList?.data}
            filter={null}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setChangeStatusCheck={setChangeStatusCheck}
            setAddProject={setAddProject}
          />
        </div>

        <ProjectTable
          type={"Other Projects"}
          client={client}
          page={"dashboard"}
          color={"#FFF"}
          data={projectList?.data}
          filter={null}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          setChangeStatusCheck={setChangeStatusCheck}
          setAddProject={setAddProject}
        />
      </div>
    </div>
  );
}

export default Dashboard;
