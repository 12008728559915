import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClient } from "../../../store/getClientSlice";
import { getEditor } from "../../../store/getEditorSlice";
import { addProject } from "../../../store/addProjectSlice";
import { STATUSES as CLIENTSTATUS } from "../../../store/getClientSlice";
import { STATUSES as EDITORSTATUS } from "../../../store/getEditorSlice";
import Swal from "sweetalert2";

const Form = (props) => {
  const dispatch = useDispatch();
  const today = new Date().toISOString().split("T")[0];
  const urlPattern = "https://.*";
  const statusList = [
    "Backlog",
    "In Progress",
    "Review",
    "Completed",
    "Declined",
  ];
  const statusList2 = [
    "In Queue",
    "Initial Review",
    "Editing",
    "Final Review",
    "Revise",
    "Approved",
  ];
  const priorityList = ["Low", "Medium", "High"];
  const stageEditing = ["Filming", "Editing"];
  const stageIR = ["Brief", "B Rolls", "Talent Sourcing"];
  const stageFR = ["Reviews"];
  const revise = ["Declined"];
  const approved = ["Completed"];
  const [title, setTitle] = useState("");
  const [brand, setBrand] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [editor, setEditor] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [briefLink, setBriefLink] = useState("");
  const [talentFootage, setTalentFootage] = useState("");
  const [frameio, setFrameio] = useState("");
  const [finalFolder, setFinalFolder] = useState("");
  const [priority, setPriority] = useState("");
  const [stage, setStage] = useState(null);
  const [project, setProject] = useState("Video Editing");
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem("token");
  const { data: clientList, status: clientStatus } = useSelector(
    (state) => state.getClient
  );
  const { data: editorList, status: editorStatus } = useSelector(
    (state) => state.getEditor
  );

  useEffect(() => {
    if (project === "Video Editing") {
      setStatus("Initial Review");
      setStage("Brief");
    } else {
      setStatus("Backlog");
      setStage("Backlog");
    }
    dispatch(getClient({ token: token }));
    dispatch(getEditor({ token: token }));
  }, [project]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.setAddProject(false);
    setIsLoading(true);
    // Perform validation here before submitting the form
    // You can check for empty fields or use regular expressions for more complex validation
    // If the form is valid, you can submit it to the server or perform further actions
    console.log("Form submitted");

    if (
      !title ||
      !brand ||
      !startDate ||
      !editor ||
      !dueDate ||
      !briefLink ||
      !talentFootage ||
      !frameio ||
      !priority ||
      !finalFolder ||
      !status
    ) {
      alert("Please fill in all the fields");
      setIsLoading(false);
      return;
    } else {
      dispatch(
        addProject({
          token: token,
          data: {
            projectName: title,
            projectType: project,
            clientId: brand,
            briefLink: briefLink,
            videoFolderName: frameio,
            talentFootageLink: talentFootage,
            finalFolder: finalFolder,
            editorId: editor,
            priority: priority,
            createdDate: startDate,
            completionDate: dueDate,
            status: status,
            currentStage: stage,
          },
        })
      )
        .unwrap()
        .then((res) => {
          props.setOpenModal(false);
          setIsLoading(false);
          setTitle("");
          setBrand("");
          setStartDate("");
          setBriefLink("");
          setDueDate("");
          setFinalFolder("");
          setFrameio("");
          setTalentFootage("");
          setStatus("Initial Review");
          setStage("Brief");
          setProject("Video Editing");
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: "Project added successfully",
            });
            props.setAddProject(true);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        })
        .catch((err) => {
          props.setOpenModal(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
  };
  const handleSelectClient = (event) => {
    const selectedOption = event.target.value;
    setBrand(selectedOption);
  };
  const handleSelectEditor = (event) => {
    const selectedOption = event.target.value;
    setEditor(selectedOption);
  };
  const handleSelectStatus = (event) => {
    const selectedOption = event.target.value;
    setStatus(selectedOption);
    if (project === "Other Projects") setStage(selectedOption);
  };
  const handleSelectStage = (event) => {
    const selectedOption = event.target.value;
    setStage(selectedOption);
  };
  const handleSelectPriority = (event) => {
    const selectedOption = event.target.value;
    setPriority(selectedOption);
  };
  return (
    <div className="ps-6 pe-6 mx-auto">
      {clientStatus === CLIENTSTATUS.LOADING ||
      clientStatus === EDITORSTATUS.LOADING ? (
        <div className="flex justify-center">
          <svg
            aria-hidden="true"
            role="status"
            className="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="#1C64F2"
            />
          </svg>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="flex justify-center mb-4">
            <div
              className={`flex justify-center items-center ${
                project === "Video Editing" ? "toggler" : "toggler3"
              } ps-2 pe-2`}
              onClick={() => {
                setProject("Video Editing");
              }}
            >
              Video Editing
            </div>
            <div
              className={`flex justify-center items-center ${
                project === "Other Projects" ? "toggler1" : "toggler2"
              } ps-2 pe-2`}
              onClick={() => {
                setProject("Other Projects");
              }}
            >
              Other Projects
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              A Name/Brief
            </label>
            <input
              type="text"
              id="title"
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block  w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="mb-4 grid grid-cols-3">
            <label
              htmlFor="brand"
              className="flex items-center col-span-1 text-sm font-medium text-gray-700"
            >
              Client
            </label>
            <select
              className="mt-1 col-span-2 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              value={brand}
              onChange={handleSelectClient}
            >
              <option value="" disabled selected>
                Select Client
              </option>
              {clientList?.data
                ?.filter((item) => item.isActive === true)
                .map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="mb-4 grid grid-cols-3">
            <label
              htmlFor="editor"
              className="flex items-center col-span-1 text-sm font-medium text-gray-700"
            >
              Editor
            </label>
            <select
              className="mt-1 col-span-2 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              value={editor}
              onChange={handleSelectEditor}
            >
              <option value="" disabled selected>
                Select Editor
              </option>
              {editorList?.data
                ?.filter((item) => item.isActive === true)
                .map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="mb-4 grid grid-cols-3">
            <label
              htmlFor="editor"
              className="flex items-center col-span-1 text-sm font-medium text-gray-700"
            >
              Status
            </label>
            {project === "Video Editing" ? (
              <select
                className="mt-1 col-span-2 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={status}
                onChange={handleSelectStatus}
              >
                {statusList2.map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
              </select>
            ) : (
              <select
                className="mt-1 col-span-2 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={status}
                onChange={handleSelectStatus}
              >
                {statusList.map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
              </select>
            )}
          </div>
          {project === "Video Editing" && (
            <div className="mb-4 grid grid-cols-3">
              <label
                htmlFor="editor"
                className="flex items-center col-span-1 text-sm font-medium text-gray-700"
              >
                Stage
              </label>

              <select
                className="mt-1 col-span-2 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                onChange={handleSelectStage}
                value={stage}
              >
                <option selected disabled>
                  Choose a option
                </option>
                {status === "Initial Review" ? (
                  <>
                    {stageIR.map((item, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </>
                ) : status === "Editing" ? (
                  <>
                    {stageEditing.map((item, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </>
                ) : status === "Final Review" ? (
                  <>
                    {stageFR.map((item, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </>
                ) : status === "Revise" ? (
                  <>
                    {revise.map((item, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </>
                ) : status === "Approved" ? (
                  <>
                    {approved.map((item, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </>
                ) : (
                  ""
                )}
              </select>
            </div>
          )}
          <div className="mb-4 grid grid-cols-3">
            <label
              htmlFor="startDate"
              className="flex items-center col-span-1 text-sm font-medium text-gray-700"
            >
              Start Date
            </label>
            <input
              type="date"
              id="startDate"
              min={today}
              className="mt-1 col-span-2 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
            />
          </div>
          <div className="mb-4 grid grid-cols-3">
            <label
              htmlFor="dueDate"
              className="flex items-center col-span-1 text-sm font-medium text-gray-700"
            >
              Due Date
            </label>
            <input
              type="date"
              id="dueDate"
              min={today}
              className="mt-1 col-span-2 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
              required
            />
          </div>

          <div className="mb-4 grid grid-cols-3">
            <label
              htmlFor="briefLink"
              className="flex items-center col-span-1 text-sm font-medium text-gray-700"
            >
              Brief Link
            </label>
            <input
              type="text"
              id="briefLink"
              className="mt-1 col-span-2 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              value={briefLink}
              onChange={(e) => setBriefLink(e.target.value)}
              pattern={urlPattern}
              title="Enter an https:// URL:"
              required
            />
          </div>
          <div className="mb-4 grid grid-cols-3">
            <label
              htmlFor="priority"
              className="flex items-center col-span-1 text-sm font-medium text-gray-700"
            >
              Priority
            </label>
            <select
              className="mt-1 col-span-2 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              value={priority}
              onChange={handleSelectPriority}
            >
              <option value="" disabled selected>
                Choose project priority
              </option>
              {priorityList.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4 grid grid-cols-3">
            <label
              htmlFor="talentFootage"
              className="flex items-center col-span-1 text-sm font-medium text-gray-700"
            >
              Talent Footage
            </label>
            <input
              type="text"
              id="talentFootage"
              className="mt-1 col-span-2 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              value={talentFootage}
              onChange={(e) => setTalentFootage(e.target.value)}
              pattern={urlPattern}
              title="Enter an https:// URL:"
              required
            />
          </div>
          <div className="mb-4 grid grid-cols-3">
            <label
              htmlFor="frameio"
              className="flex items-center col-span-1 text-sm font-medium text-gray-700"
            >
              Framio
            </label>
            <input
              type="text"
              id="frameio"
              className="mt-1 col-span-2 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              value={frameio}
              onChange={(e) => setFrameio(e.target.value)}
              pattern={urlPattern}
              title="Enter an https:// URL:"
              required
            />
          </div>
          <div className="mb-4 grid grid-cols-3">
            <label
              htmlFor="finalFolder"
              className="flex items-center col-span-1 text-sm font-medium text-gray-700"
            >
              Final Folder
            </label>
            <input
              type="text"
              id="finalFolder"
              className="mt-1 col-span-2 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              value={finalFolder}
              onChange={(e) => {
                setFinalFolder(e.target.value);
              }}
              pattern={urlPattern}
              title="Enter an https:// URL:"
              required
            />
          </div>
          <div className="mt-5 flex justify-center">
            {isLoading ? (
              <button disabled type="button" className="login-btn px-4 py-2">
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 mr-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Loading...
              </button>
            ) : (
              <button
                type="submit"
                className="login-btn inline-flex items-center px-4 py-2 "
              >
                Submit
              </button>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default Form;
