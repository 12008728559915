import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClient } from "../../../store/getClientSlice";
import { getEditor } from "../../../store/getEditorSlice";
import { STATUSES as CLIENTSTATUS } from "../../../store/getClientSlice";
import Project from "../Project/project";
import sortBy from "../../../assets/images/sortBy.svg";
import { Listbox, Transition } from "@headlessui/react";

function Header(props) {
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const [brand, setBrand] = useState("");
  const [sort, setSort] = useState("");
  const token = localStorage.getItem("token");
  const { data: clientList, status: clientStatus } = useSelector(
    (state) => state.getClient
  );

  useEffect(() => {
    dispatch(getClient({ token: token }));
  }, []);
  const handleSelectClient = (event) => {
    const selectedOption = event.target.value;
    setBrand(selectedOption);
    props.setClient(selectedOption);
  };
  const handleSelectProject = (event) => {
    const selectedOption = event.target.value;
    props.setProject(selectedOption);
  };
  const handleSelectSort = (event) => {
    const selectedOption = event;
    setSort(selectedOption);
    props.setSort(selectedOption);
  };
  return (
    <div className="ps-6 sm:flex items-center justify-between p-3 border-b">
      <div className="">
        {role === "admin" ? (
          <div className="sidebar-heading mb-3 sm:mb-0">Admin Dashboard</div>
        ) : (
          <div className="sidebar-heading mb-3 sm:mb-0">Dashboard</div>
        )}
      </div>
      <div>
        <div className="flex">
          <div className="">
            <Project setAddProject={props.setAddProject} />
            {/* <div style={{ color: "#8E8E8E" }}>Sort by</div> */}
          </div>

          <div>
            <Listbox value={sort} onChange={handleSelectSort}>
              <Listbox.Button style={{ marginRight: "10px" }}>
                <img src={sortBy} alt="" />
              </Listbox.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
                className="absolute z-10 mt-3 bg-white rounded-lg shadow p-2 cursor-pointer"
              >
                <Listbox.Options>
                  <Listbox.Option value="">Sort by</Listbox.Option>
                  <Listbox.Option value="Brand">Brand</Listbox.Option>
                  <Listbox.Option value="Date Created">
                    Date Created
                  </Listbox.Option>
                  <Listbox.Option value="Deadline">Deadline</Listbox.Option>
                </Listbox.Options>
              </Transition>
            </Listbox>
          </div>
          <select className="project-filter" onChange={handleSelectProject}>
            <option value="All Projects" selected>
              All Projects
            </option>
            <option value="My Projects">My Projects</option>
          </select>
          <div>
            <label for="table-search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-3 h-3 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="table-search"
                style={{ borderRadius: "0px 30px 30px 0px" }}
                className="block p-1 pl-7 text-sm text-gray-900 border border-gray-300 sm:w-60 w-30 bg-none "
                placeholder="Search for items"
                onChange={(e) => {
                  props.setSearch(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
