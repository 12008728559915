import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClient } from "../../../store/getClientSlice";
import { getEditor } from "../../../store/getEditorSlice";
import { STATUSES as CLIENTSTATUS } from "../../../store/getClientSlice";
import Project from "../Project/project";
import Swal from "sweetalert2";

function Header(props) {
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const [brand, setBrand] = useState("");
  const token = localStorage.getItem("token");
  const { data: clientList, status: clientStatus } = useSelector(
    (state) => state.getClient
  );

  useEffect(() => {
    dispatch(getClient({ token: token }))
      .then((res) => {})
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  }, []);

  const handleSelectClient = (event) => {
    const selectedOption = event.target.value;
    setBrand(selectedOption);
    props.setClient(selectedOption);
  };
  return (
    <div className="sm:flex items-center justify-between p-5">
      <div className="flex mb-3">
        <div>
          <select
            className={`flex justify-center items-center whitespace-nowrap ${
              props.project === "Video Editing" ? "toggler4" : "toggler5"
            } ps-2 pe-2`}
            value={brand}
            onChange={handleSelectClient}
            onClick={() => {
              props.setProject("Video Editing");
            }}
          >
            <option value="" selected>
              All Brands
            </option>
            {clientList?.data?.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div
          className={`flex justify-center items-center whitespace-nowrap ${
            props.project === "Other Projects" ? "toggler1" : "toggler2"
          } ps-2 pe-2`}
          onClick={() => {
            props.setProject("Other Projects");
          }}
        >
          Other Projects
        </div>
        <Project
          page={"project-management"}
          setAddProject={props.setAddProject}
        />
      </div>

      <div className="">
        <label for="table-search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-3 h-3 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            type="text"
            id="table-search"
            style={{ borderRadius: "30px 30px 30px 30px" }}
            className="block p-1 pl-7 text-sm text-gray-900 border border-gray-300 sm:w-60 w-30 bg-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="Search for items"
            onChange={(e) => {
              props.setSearch(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
