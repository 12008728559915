import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import pic from "../../assets/images/profile-pic.png";
import { editUser } from "../../store/editUser";
import { Image } from "../../store/imageSlice";
import Logout from "../Logout/logout";
function Settings() {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [username, setUsername] = useState(localStorage.getItem("name"));
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [image, setImage] = useState(localStorage.getItem("image"));
  const [imageURL, setImageURL] = useState(localStorage.getItem("image"));
  const [errors, setErrors] = useState({});
  const [imageUpdate, setImageUpdate] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform validation here
    const validationErrors = {};
    if (!image) {
      validationErrors.image = "Image is required";
    }
    if (image?.size > 1000000) {
      validationErrors.image = "Image size must less than 1mb";
    }
    if (!email) {
      validationErrors.email = "Email is required";
    }
    if (!username) {
      validationErrors.username = "Username is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Submit the form
      console.log("Form submitted");
      const formData = new FormData();
      formData.append("image", image);
      if (imageUpdate) {
        dispatch(Image(formData))
          .unwrap()
          .then((res1) => {
            if (res1.status) {
              dispatch(
                editUser({
                  token: token,
                  data: {
                    name: username,
                    email: email,
                    image: res1.imageUrl,
                  },
                })
              )
                .unwrap()
                .then((res) => {
                  if (res?.status) {
                    localStorage.setItem("image", res1.imageUrl);
                    localStorage.setItem("name", username);
                    localStorage.setItem("email", email);

                    Swal.fire({
                      icon: "success",
                      title: "User updated successfully",
                    });
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                  });
                });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        dispatch(
          editUser({
            token: token,
            data: {
              name: username,
              email: email,
              image: image,
            },
          })
        )
          .unwrap()
          .then((res) => {
            if (res?.status) {
              localStorage.setItem("image", image);
              localStorage.setItem("name", username);
              localStorage.setItem("email", email);
              Swal.fire({
                icon: "success",
                title: "User updated successfully",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    }
  };
  return (
    <div className="sm:ml-16">
      <div className="flex justify-between">
        <div className="sidebar-heading m-8">Settings</div>
        <div className="m-8">
          <Logout />
        </div>
      </div>
      <div
        className="m-8 p-4 mt-12"
        style={{
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
        }}
      >
        <div
          className="sidebar-heading m-4"
          style={{ fontSize: "20px", fontWeight: "700" }}
        >
          Profile Details
        </div>

        <form onSubmit={handleSubmit} className="sm:flex flex-row">
          <div className="sm:me-12 flex justify-center mb-3">
            <div className="relative cursor-pointer">
              <input
                className="absolute inset-0 w-full h-full opacity-0 z-50"
                type="file"
                onChange={(e) => {
                  if (e.target.files[0].size <= 1000000) {
                    let error = errors;
                    delete error.image;
                    setErrors(error);
                    let url = URL.createObjectURL(e.target.files[0]);
                    setImageUpdate(true);
                    setImage(e.target.files[0]);
                    setImageURL(url);
                  } else {
                    let url = URL.createObjectURL(e.target.files[0]);
                    setImage(e.target.files[0]);
                    setImageURL(url);
                    setImageUpdate(true);
                    let error = errors;
                    error.image = "Image size must less than 1mb";
                    setErrors(error);
                  }
                }}
              />

              <div className="flex justify-center cursor-pointer mb-1">
                {image ? (
                  <img
                    id="preview_img"
                    class="object-cover rounded-full"
                    style={{ width: "200px", height: "200px" }}
                    src={imageURL}
                    alt="Current profile photo"
                  />
                ) : (
                  <img
                    id="preview_img"
                    class="object-cover rounded-full"
                    style={{ width: "200px", height: "200px" }}
                    src={pic}
                    alt="Current profile photo"
                  />
                )}
              </div>
              {errors.image && (
                <p className="flex justify-center login-text2 text-red-500 mb-1">
                  {errors.image}
                </p>
              )}
            </div>
          </div>
          <div
            className="sm:me-12"
            style={{ border: "1px solid rgba(173, 181, 189, 1)" }}
          ></div>
          <div>
            <div className="flex flex-col mb-3">
              <label htmlFor="username" className="login-text mb-1">
                Username
              </label>
              <input
                type="username"
                id="username"
                placeholder="Enter Username"
                value={username}
                onChange={(e) => {
                  if (e.target.value) {
                    let error = errors;
                    delete error.username;
                    setErrors(error);
                  }
                  setUsername(e.target.value);
                }}
                className={`login-input outline-none focus:border-blue-500 border-2 ${
                  errors.username ? "border-red-500" : "border-gray-300"
                } p-2`}
              />
              {errors.username && (
                <p className="login-text2 text-red-500">{errors.username}</p>
              )}
            </div>
            <div className="flex flex-col mb-3">
              <label htmlFor="email" className="login-text mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => {
                  if (e.target.value) {
                    let error = errors;
                    delete error.email;
                    setErrors(error);
                  }
                  setEmail(e.target.value);
                }}
                className={`login-input focus:outline-none border ${
                  errors.email ? "border-red-500" : "border-gray-300"
                } p-2`}
              />
              {errors.email && (
                <p className="login-text2 text-red-500">{errors.email}</p>
              )}
            </div>

            <div className="flex justify-center">
              <button type="submit" className="login-btn px-5 py-2 mt-3">
                Edit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Settings;
