import React, { useState } from "react";
import Modal from "react-modal";
import { addComment } from "../../../store/addCommentSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
Modal.setAppElement("#root");

function Comment(props) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  // const [openModal, setOpenModal] = useState(false);
  const [comment, setComment] = useState(props?.comment[0]?.commentText);

  const handleKeyDown = (event, id) => {
    if (event.key === "Enter") {
      props.setAddProject(false);
      dispatch(
        addComment({
          token: token,
          data: { commentText: comment, projectId: id },
        })
      )
        .unwrap()
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Comment added successfully",
          });
          props.setAddProject(true);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
  };

  return (
    <>
      <input
        type="text"
        name="comment"
        id="comment"
        value={comment}
        className={`${props.style} bg-transparent p-0 m-0 focus:outline-2 focus:outline-blue-500 focus:rounded`}
        style={{ boxShadow: "none", border: "none" }}
        placeholder="Add Comments"
        onChange={(e) => {
          setComment(e.target.value);
        }}
        onKeyDown={(e) => handleKeyDown(e, props.projectId)}
      />
    </>
  );
}

export default Comment;
