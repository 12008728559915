import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { getProject } from "../../../store/getProjectSlice";

import ProjectTable from "../ProjectTable/projectTable";
import Header from "./header";

function ProjectManagement() {
  const statusList = [
    "Backlog",
    "In Progress",
    "Review",
    "Completed",
    "Declined",
  ];
  const statusList2 = [
    "Brief",
    "B Rolls",
    "Talent Sourcing",
    "Filming",
    "Editing",
    "Reviews",
    "Completed",
    "Declined",
  ];
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const [client, setClient] = useState();
  const [project, setProject] = useState("Video Editing");
  const [addProject, setAddProject] = useState(false);
  const [search, setSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [projectListData, setProjectListData] = useState([]);
  const [changeStatusCheck, setChangeStatusCheck] = useState(true);

  const { data: projectList, status: projectStatus } = useSelector(
    (state) => state.getProject
  );

  useEffect(() => {
    setIsLoading(true);
    // if (project === "My Projects") {
    //   if (search)
    //     dispatch(getProject({ token: token, status: true, name: search }));
    //   else dispatch(getProject({ token: token, status: true }));
    // } else {
    if (search && changeStatusCheck) {
      dispatch(getProject({ token: token, name: search }))
        .unwrap()
        .then((res) => {
          setProjectListData(res?.data);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    } else if (changeStatusCheck) {
      dispatch(getProject({ token: token }))
        .unwrap()
        .then((res) => {
          setProjectListData(res?.data);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
  }, [client, search, project, changeStatusCheck]);

  const location = useLocation();

  useEffect(() => {
    const cellId = location.state && location.state.cellId;

    if (cellId) {
      // Scroll to the element with the specified cellId
      const element = document.getElementById(cellId);
      const list = projectList?.data?.filter((item) => item.id === cellId);

      if (list) {
        setProject(list[0].projectType);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (addProject) {
      if (search && changeStatusCheck) {
        dispatch(getProject({ token: token, name: search }))
          .unwrap()
          .then((res) => {
            setProjectListData(res?.data);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      } else if (changeStatusCheck) {
        dispatch(getProject({ token: token }))
          .unwrap()
          .then((res) => {
            setProjectListData(res?.data);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    }
  }, [addProject]);

  useEffect(() => {
    //console.log(projectListData);
  }, [projectListData]);

  return (
    <>
      <div className="sm:ml-16 flex flex-col lg:h-screen">
        <div className="sidebar-heading m-4 ms-5">Project Management</div>

        <>
          <Header
            setClient={setClient}
            setSearch={setSearch}
            setProject={setProject}
            project={project}
            setAddProject={setAddProject}
          />
        </>

        <div className="overflow-x-auto">
          {project === "Video Editing" ? (
            <>
              {statusList2.map((item, index) => (
                <div
                  key={index}
                  className="mt-5 mb-3"
                  style={{ zIndex: 10 - index, position: "relative" }}
                >
                  <ProjectTable
                    project={project}
                    tableID={index}
                    tableName={item}
                    client={client}
                    page={"project-management"}
                    filter={item}
                    data={projectListData}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    setProjectListData={setProjectListData}
                    setChangeStatusCheck={setChangeStatusCheck}
                    setAddProject={setAddProject}
                  />
                </div>
              ))}
            </>
          ) : (
            <>
              {statusList.map((item, index) => (
                <div
                  key={index}
                  className="mt-5 mb-3"
                  style={{ zIndex: 10 - index, position: "relative" }}
                >
                  <ProjectTable
                    project={project}
                    tableID={index}
                    tableName={item}
                    client={client}
                    page={"project-management"}
                    filter={item}
                    data={projectListData}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    setProjectListData={setProjectListData}
                    setChangeStatusCheck={setChangeStatusCheck}
                    setAddProject={setAddProject}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ProjectManagement;
