import React, { useEffect, useState } from "react";
import Comment from "../Comment/comment";
import { useDrag, useDrop } from "react-dnd";
import imgIcon from "../../../assets/images/Asignee1.png";
import { Listbox, Transition } from "@headlessui/react";
import ProjectEdit from "../EditProject/editProject";
import EditCell from "./EditCell";
import { editProject } from "../../../store/editProjectSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

const statusList = [
  "Backlog",
  "In Progress",
  "Review",
  "Completed",
  "Declined",
];
const statusList2 = [
  "In Queue",
  "Initial Review",
  "Editing",
  "Final Review",
  "Revise",
  "Approved",
];
const colorList1 = [
  "rgba(255, 201, 152, 0.7)",
  "rgba(173, 214, 255, 1)",
  "rgba(213, 205, 255, 1)",
  "rgba(210, 255, 234, 1)",
  "rgba(255, 204, 204, 1)",
];
const colorList2 = [
  "rgba(173, 214, 255, 1)",
  "rgba(255, 204, 204, 1)",
  "rgba(213, 205, 255, 1)",
  "rgba(255, 247, 219, 1)",
  "rgba(240, 240, 240, 1)",
  "rgba(210, 255, 234, 1)",
];

const TableRow = ({
  item,
  handleSelectStatus,
  handleSelectEditor,
  page,
  project,
  editorList,
  index,
  setAddProject,
  key,
  id,
}) => {
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  let selectedColor = "";
  const [title, setTitle] = useState(item.projectName);
  const [brand, setBrand] = useState(item.client.id);
  const [status, setStatus] = useState(item.currentStatus);
  const [startDate, setStartDate] = useState(item.createdDate);
  const [editor, setEditor] = useState(item.editor.id);
  const [dueDate, setDueDate] = useState(item.completionDate);
  const [briefLink, setBriefLink] = useState(item.briefLink);
  const [talentFootage, setTalentFootage] = useState(item.talentFootageLink);
  const [frameio, setFrameio] = useState(item.videoFolderName);
  const [finalFolder, setFinalFolder] = useState(item.finalFolder);
  const [priority, setPriority] = useState(item.priority);
  const [stage, setStage] = useState(item.currentStage);

  const location = useLocation();

  useEffect(() => {
    const cellId = location.state && location.state.cellId;
    if (cellId) {
      // Scroll to the element with the specified cellId
      const element = document.getElementById(cellId);

      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location.state]);

  function handleSubmit(e) {
    if (e.key === "Enter") {
      setAddProject(false);
      // Perform validation here before submitting the form
      // You can check for empty fields or use regular expressions for more complex validation
      // If the form is valid, you can submit it to the server or perform further actions
      console.log("Form submitted");

      if (
        !title ||
        !brand ||
        !startDate ||
        !editor ||
        !dueDate ||
        !briefLink ||
        !talentFootage ||
        !frameio ||
        !priority ||
        !finalFolder ||
        !status
      ) {
        alert("Please fill in all the fields");
        return;
      } else {
        dispatch(
          editProject({
            token: token,
            id: item.id,
            data: {
              projectName: title,
              projectType: project,
              clientId: brand,
              briefLink: briefLink,
              videoFolderName: frameio,
              talentFootageLink: talentFootage,
              editorId: editor,
              priority: priority,
              createdDate: startDate,
              completionDate: dueDate,
              status: status,
              currentStage: stage,
            },
          })
        )
          .unwrap()
          .then((res) => {
            if (res.status) {
              Swal.fire({
                icon: "success",
                title: "Project edited successfully",
              });
              setAddProject(true);
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              });
            }
            setAddProject(true);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    }
  }

  if (item.projectType === "Other Projects") {
    statusList.map((data, index) => {
      if (data === item.currentStatus) selectedColor = colorList1[index];
      else return;
    });
  } else {
    statusList2.map((data, index) => {
      if (data === item.currentStatus) selectedColor = colorList2[index];
      else return;
    });
  }

  const [{ isDragging }, drag] = useDrag({
    type: "row",
    item: { id: item.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const calculateRemainingDays = (item) => {
    const completionTime = new Date(item.completionDate);
    const createdTime = new Date(item.createdDate);
    const remainingDays = Math.ceil(
      (completionTime - createdTime) / (1000 * 3600 * 24)
    );

    return remainingDays;
  };

  return (
    <>
      {project ? (
        <tr
          className=" border-b"
          ref={drag}
          key={key}
          id={id}
          style={{
            opacity: isDragging ? 0.5 : 1,
            backgroundColor:
              index === 0 ? "rgba(245, 245, 247, 1)" : "rgba(253, 253, 253, 1)",
          }}
        >
          <td style={{ width: "10px" }}>
            <ProjectEdit setAddProject={setAddProject} item={item} />
          </td>
          <td className="px-4 py-1 ">
            <div style={{ width: "180px" }}>
              <div className="tooltip editor-project-table-text-2 mb-1 mt-2">
                <EditCell
                  handleSubmit={handleSubmit}
                  type={"text"}
                  style={"editor-project-table-text-2"}
                  value={title}
                  setValue={setTitle}
                />
              </div>

              <div>{item.client?.name}</div>
            </div>
          </td>
          <td className="px-4 py-1 ">
            <EditCell
              handleSubmit={handleSubmit}
              type={"date"}
              style={"editor-project-table-text"}
              value={startDate}
              setValue={setStartDate}
            />
          </td>
          {/* <td className="px-4 py-1 ">{item.editor?.name}</td> */}
          <td className="px-4 py-1">
            {role === "admin" ? (
              <Listbox
                onChange={(e) => {
                  handleSelectEditor(e, item.id);
                }}
              >
                <Listbox.Button>
                  <div className="w-16 flex">
                    <img src={imgIcon} alt="" className="w-4 me-1" />
                    {item.editor.name}
                  </div>
                </Listbox.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                  className="absolute z-50 bg-white rounded-lg shadow"
                >
                  <Listbox.Options>
                    {editorList
                      ?.filter((item) => item.isActive === true)
                      .map((item, index) => (
                        <Listbox.Option key={index} value={item}>
                          {({ active, selected }) => (
                            <li className="editor-project-table-option flex">
                              <img src={imgIcon} alt="" className="w-4 me-2" />
                              {item.name}
                            </li>
                          )}
                        </Listbox.Option>
                      ))}
                  </Listbox.Options>
                </Transition>
              </Listbox>
            ) : (
              <div className="flex w-16">
                <img src={imgIcon} alt="" className="w-4 me-1" />{" "}
                {item.editor.name}
              </div>
            )}
          </td>

          <td className="px-4 py-1 ">
            <EditCell
              handleSubmit={handleSubmit}
              type={"date"}
              min={startDate}
              style={"editor-project-table-text"}
              value={dueDate}
              setValue={setDueDate}
            />
          </td>
          <td className="px-4 py-1 ">
            <Comment
              style={"editor-project-table-text"}
              comment={item.comments}
              projectId={item.id}
              setAddProject={setAddProject}
            />
          </td>
          <td className="px-4 py-1 ">
            <EditCell
              handleSubmit={handleSubmit}
              style={"editor-project-table-text"}
              value={item.projectName}
              setValue={setBriefLink}
              link={briefLink}
            />
          </td>
          <td className="px-4 py-1 ">
            <EditCell
              handleSubmit={handleSubmit}
              style={"editor-project-table-text"}
              value={item.projectName}
              setValue={setTalentFootage}
              link={talentFootage}
            />
          </td>
          <td className="px-4 py-1 ">
            <EditCell
              handleSubmit={handleSubmit}
              style={"editor-project-table-text"}
              value={item.projectName}
              setValue={setFrameio}
              link={frameio}
            />
          </td>
          <td className="px-4 py-1 ">
            <EditCell
              handleSubmit={handleSubmit}
              style={"editor-project-table-text"}
              value={item.projectName}
              setValue={setFinalFolder}
              link={finalFolder}
            />
          </td>
        </tr>
      ) : (
        <tr className=" border-b" style={{ opacity: isDragging ? 0.5 : 1 }}>
          <td style={{ width: "10px" }}>
            <ProjectEdit setAddProject={setAddProject} item={item} />
          </td>
          <td className="px-4 py-1 ">
            <div style={{ width: "180px" }}>
              <div className="tooltip mb-1 mt-2">
                <EditCell
                  handleSubmit={handleSubmit}
                  type={"text"}
                  style={"editor-project-table-text-2"}
                  value={title}
                  setValue={setTitle}
                />
              </div>

              <div>{item.client?.name}</div>
            </div>
          </td>
          <td className="px-4 py-1 ">
            <EditCell
              handleSubmit={handleSubmit}
              type={"date"}
              style={"editor-project-table-text"}
              value={startDate}
              setValue={setStartDate}
            />
          </td>
          {/* <td className="px-4 py-1 ">{item.editor?.name}</td> */}
          <td className="px-4 py-1 ">
            {role === "admin" ? (
              <Listbox
                onChange={(e) => {
                  handleSelectEditor(e, item.id);
                }}
              >
                <Listbox.Button>
                  <div className="w-16 flex">
                    <img src={imgIcon} alt="" className="w-4 me-1" />
                    {item.editor.name}
                  </div>
                </Listbox.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                  className="absolute z-50 bg-white rounded-lg shadow"
                >
                  <Listbox.Options>
                    {editorList
                      ?.filter((item) => item.isActive === true)
                      .map((item, index) => (
                        <Listbox.Option key={index} value={item}>
                          {({ active, selected }) => (
                            <li className="editor-project-table-option flex">
                              <img src={imgIcon} alt="" className="w-4 me-2" />
                              {item.name}
                            </li>
                          )}
                        </Listbox.Option>
                      ))}
                  </Listbox.Options>
                </Transition>
              </Listbox>
            ) : (
              <div className="flex w-16">
                <img src={imgIcon} alt="" className="w-4 me-1" />{" "}
                {item.editor.name}
              </div>
            )}
          </td>
          {page === "dashboard" && (
            <td className="px-4 py-1">
              <Listbox
                value={item.currentStatus}
                onChange={(e) => {
                  handleSelectStatus(e, item.id, item.projectType);
                }}
              >
                <Listbox.Button
                  style={{
                    borderRadius: "30px",
                    backgroundColor: selectedColor,
                    border: "none",
                    width: "100px",
                  }}
                  className="p-2"
                >
                  {item.currentStatus}
                </Listbox.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                  className="absolute z-30 bg-white rounded-lg shadow"
                >
                  {item.projectType === "Other Projects" ? (
                    <Listbox.Options>
                      {statusList.map((item, index) => (
                        <Listbox.Option key={index} value={item}>
                          {({ active, selected }) => (
                            <li
                              className="editor-project-table-option"
                              style={{ background: colorList1[index] }}
                            >
                              {item}
                            </li>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  ) : (
                    <Listbox.Options>
                      {statusList2.map((item, index) => (
                        <Listbox.Option key={index} value={item}>
                          {({ active, selected }) => (
                            <li
                              className="editor-project-table-option"
                              style={{ background: colorList2[index] }}
                            >
                              {item}
                            </li>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  )}
                </Transition>
              </Listbox>
            </td>
          )}
          <td className="px-4 py-1 ">{calculateRemainingDays(item)} Days</td>
          <td className="px-4 py-1 ">
            <Comment
              style={"editor-project-table-text"}
              comment={item.comments}
              projectId={item.id}
              setAddProject={setAddProject}
            />
          </td>
          <td className="px-4 py-1 ">
            <EditCell
              handleSubmit={handleSubmit}
              style={"editor-project-table-text"}
              value={item.projectName}
              setValue={setBriefLink}
              link={briefLink}
            />
          </td>
          <td className="px-4 py-1 ">
            <EditCell
              handleSubmit={handleSubmit}
              style={"editor-project-table-text"}
              value={item.projectName}
              setValue={setTalentFootage}
              link={talentFootage}
            />
          </td>
          <td className="px-4 py-1 ">
            <EditCell
              handleSubmit={handleSubmit}
              style={"editor-project-table-text"}
              value={item.projectName}
              setValue={setFrameio}
              link={frameio}
            />
          </td>
          <td className="px-4 py-1 ">
            <EditCell
              handleSubmit={handleSubmit}
              style={"editor-project-table-text"}
              value={item.projectName}
              setValue={setFinalFolder}
              link={finalFolder}
            />
          </td>
        </tr>
      )}
    </>
  );
};
export default TableRow;
